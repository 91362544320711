<template>
  <div>
    <BaseContainer class="pt-40 pb-16 relative z-20 min-h-screen">
      <BaseHeadingAndText
        heading="A Dog A Day"
        headingSize="6xl"
        headingColor="cdlpink"
        textSize="xl"
      >
        It's simple.<br />
        On Monday, December 13, 2021, we will introduce our A Dog a Day NFTs.<br />
        1 Day. 1 Dog.<br />
        Everyday for 7 days, we will select one dog we are trying to save.<br />
        Dogs that need money for radiotherapy to treat their cancer…<br />
        Dogs that are up for adoption living in extremely poor conditions…<br />
        We will select one of the dogs we are trying to save.
      </BaseHeadingAndText>

      <!-- NFT preview -->
      <div class="w-full py-5">
        <div v-if="currentDog">
          <BaseHeadingAndText
            class="mt-10"
            heading="Dog of the day"
            headingSize="4xl"
            headingColor="cdlpink"
            textSize="xl"
          />
          <!-- <component class="w-full md:w-1/2" :is="componentFile" /> -->
          <img
            :src="`/dogaday/${currentDog.image}`"
            class="w-full md:w-1/2 border-4 border-white"
          />
        </div>

        <!-- previous dogs -->
        <div v-if="previousDogs.length > 0">
          <BaseHeadingAndText
            class="mt-10"
            heading="Previous dogs"
            headingSize="2xl"
            headingColor="cdlpink"
            textSize="xl"
          />
          <div class="flex flex-row flex-wrap">
            <div v-for="dog in previousDogs" :key="dog.order">
              <img
                :src="`/dogaday/${dog.image}`"
                class="w-48 border-4 mr-4 mb-4 border-white"
              />
            </div>
          </div>
        </div>
      </div>

      <BaseHeadingAndText
        class="mt-10"
        heading="The rules are simple:"
        headingSize="2xl"
        headingColor="cdlpink"
        textSize="xl"
      >
        <ul>
          <li>• The contest starts on Monday at 00:00:01 PST</li>
          <li>• The contest runs daily until 23:59:59 PST</li>
          <li>• The contest runs for 7 days until Sunday 23:59:59 PST</li>
          <li>
            • The top 5 contributors will receive an NFT from our Cuddle Dogs
            collection
          </li>
          <li>
            • The top contributor will also receive the A Dog A Day custom NFT
            artwork of the doggo we're saving
          </li>
          <li>
            • 3 contributors outside of the top 5 will be randomly chosen to
            receive a Cuddle Dog NFT
          </li>
          <li>• Minimum contribution of 0.05 ETH to qualify</li>
        </ul>
        Once the contest ends, we will announce the winners based on blockchain
        timestamps displayed on etherscan.
      </BaseHeadingAndText>

      <BaseHeadingAndText
        class="mt-10 break-words"
        heading="You can send to the following ETH address:"
        headingSize="2xl"
        headingColor="cdlpink"
        textSize="xl"
      >
        0xf3F2308aE00f3821b2D21d175eea2032d2fc806f<br />
        view on:
        <a
          class="underline mr-2"
          href="https://etherscan.io/address/0xf3F2308aE00f3821b2D21d175eea2032d2fc806f"
          target="_blank"
          >Etherscan</a
        >
        <a
          class="underline mr-2"
          href="https://polygonscan.com/address/0xf3F2308aE00f3821b2D21d175eea2032d2fc806f"
          target="_blank"
          >Polygonscan</a
        >
        <a
          class="underline"
          href="https://bscscan.com/address/0xf3F2308aE00f3821b2D21d175eea2032d2fc806f"
          target="_blank"
          >BscScan</a
        >
      </BaseHeadingAndText>
    </BaseContainer>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/common/Footer"

export default {
  name: "DogADay",
  components: {
    Footer,
  },
  data() {
    return {
      file: "",
      now: new Date(),
      dogs: [
        {
          order: 1,
          dateFrom: "2021-12-13T08:00:01",
          dateTo: "2021-12-14T07:59:59",
          name: "Harvey",
          image: "cuddle - a dog a day - 1 - harvey - post.jpg",
        },
        {
          order: 2,
          dateFrom: "2021-12-14T08:00:01",
          dateTo: "2021-12-15T07:59:59",
          name: "Maggie",
          image: "cuddle - a dog a day - 2 - maggie - post.jpg",
        },
        {
          order: 3,
          dateFrom: "2021-12-15T08:00:01",
          dateTo: "2021-12-16T07:59:59",
          name: "Siouxue",
          image: "cuddle - a dog a day - 3 - siouxue - post.jpg",
        },
        {
          order: 4,
          dateFrom: "2021-12-16T08:00:01",
          dateTo: "2021-12-17T07:59:59",
          name: "Hendrix",
          image: "cuddle - a dog a day - 4 - hendrix - post.jpg",
        },
        {
          order: 5,
          dateFrom: "2021-12-17T08:00:01",
          dateTo: "2021-12-18T07:59:59",
          name: "Nobby",
          image: "cuddle - a dog a day - 5 - nobby - post.jpg",
        },
        {
          order: 6,
          dateFrom: "2021-12-18T08:00:01",
          dateTo: "2021-12-19T07:59:59",
          name: "Jessy B",
          image: "cuddle - a dog a day - 6 - jessy b - post.jpg",
        },
        {
          order: 7,
          dateFrom: "2021-12-19T08:00:01",
          dateTo: "2021-12-20T07:59:59",
          name: "Mila",
          image: "cuddle - a dog a day - 7 - mila - post.jpg",
        },
      ],
    }
  },
  computed: {
    currentDog() {
      let now = new Date(this.now).getTime()
      return this.dogs.find(
        (d) =>
          new Date(d.dateFrom).getTime() <= now &&
          new Date(d.dateTo).getTime() >= now
      )
    },
    previousDogs() {
      let now = new Date(this.now).getTime()
      return this.dogs.filter((d) => new Date(d.dateTo).getTime() < now)
    },
  },
  created() {
    setInterval(this.getNow, 1000)
  },
  methods: {
    getNow: function () {
      this.now = new Date()
    },
  },
}
</script>
